import { Link } from "react-router-dom";
import "./footer.scoped.scss"

export const Footer = () => {
    return (
    <div className="footer">
        
      <div className="content_wrap">

        <div className="content">
          <div className="content_part">
            <h4>Slovensko</h4>
            <div className="box">
                <div className="icon">
                  <i className="fal fa-map-marker-alt" />
                </div>
                <div className="text">
                  <h4>Office Bratislava</h4>
                  <p>
                    Jána Stanislava 28A
                    <br />
                    841 05 Bratislava
                  </p>
                </div>
            </div>
            <div className="box">
              <div className="icon">
                <i className="fal fa-map-marker-alt" />
              </div>
              <div className="text">
                <h4>Office Poprad</h4>
                <p>
                  Dlhé hony 5031/6
                  <br />
                  058 01 Poprad
                </p>
              </div>
            </div>
          </div>


          <div className="content_part">
            <h4>Česká republika</h4>
            <div className="box">
              <div className="icon">
                <i className="fal fa-map-marker-alt" />
              </div>
              <div className="text">
                <h4>Office Praha</h4>
                <p>
                  Plynární 1617
                  <br />
                  Budova Visionary
                  <br />
                  170 00 Praha 7
                </p>
              </div>
            </div>
          </div>

          <div className="content_part">
            <h4>Nemecko / Rakúsko</h4>
            <div className="box">
              <div className="icon">
                <i className="fal fa-map-marker-alt" />
              </div>
              <div className="text">
                <h4>Office Berlin</h4>
                <p>
                  Augsburger Str. 33
                  <br />
                  107 89 Berlin
                </p>
              </div>
            </div>
          </div>

          <div className="content_part contact">
            <h4>Kontakt</h4>
            
            <div className="box">
              <div className="icon">
                  <i className="fal fa-envelope" />
                </div>
              <div className="text theme-color">
                <span>
                  <Link to="mailto:info@bizpartnergroup.com">info@bizpartnergroup.com</Link>
                </span>
              </div>
            </div>

            <div className="box">
              <div className="icon">
                  <i className="fas fa-phone-alt" />
                </div>
              <div className="text theme-color">
                <span>
                  <Link to="tel:+421 948 412 360">+421 948 412 360</Link>
                </span>
              </div>
            </div>

            <div className="box web">
              <div className="text theme-color">
                <span>člen skupiny BizPartner Group<br/></span>
                <span>
                  <Link target="_blank" to="https://www.bizpartnergroup.com">
                    www.bizpartnergroup.com
                  </Link>
                </span>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div className="copyright_wrap">
          <div className="copyright">
            <p>© 2007 - 2024 BizPartner Group</p>
            <div className="links">
              <span>
                <Link target="_blank" to="https://www.bizpartnergroup.com/pouzivanie-cookies">
                  Používanie cookies
                </Link>
              </span>
              <span>
                <Link target="_blank" to="https://www.bizpartnergroup.com/ochrana-osobnych-udajov">
                  Ochrana osobných údajov
                </Link>
              </span>
              <span>
                <Link target="_blank" to="https://www.bizpartnergroup.com/GDPR-BizPartner-Investments.pdf">
                  GDPR
                </Link>
              </span>
              <span>
                <Link target="_blank" to="https://blog.bizpartnergroup.com">
                  Blog
                </Link>
              </span>
            </div>
          </div>
          <div className="socials">
          <ul>
              <li>
                <Link target="_blank" to="https://www.facebook.com/bizpartnergroup">
                  <i className="fab fa-facebook-f" />
                  <i className="fab fa-facebook-f" />
                </Link>
              </li>
              <li>
                <Link target="_blank" to="https://www.youtube.com/@bizpartnergroup">
                  <i className="fab fa-youtube" />
                  <i className="fab fa-youtube" />
                </Link>
              </li>
              <li>
                <Link target="_blank" to="https://www.instagram.com/bizpartnergroup">
                  <i className="fab fa-instagram" />
                  <i className="fab fa-instagram" />
                </Link>
              </li>
              <li>
                <Link target="_blank" to="https://www.linkedin.com/company/bizpartner">
                  <i className="fab fa-linkedin" />
                  <i className="fab fa-linkedin" />
                </Link>
              </li>
            </ul>
          </div>
      </div>

    </div>
);
}